import * as React from "react"
// import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"


import { Container, Row, Col, Carousel } from "react-bootstrap"



const IndexPage = () => (
  <Layout>
    <Seo title="E-bike Hire & Private e-bike Tours" />

    <Carousel interval={5000}>
      <Carousel.Item>
        <StaticImage
          src="../images/ebikes/01-ebike.jpg"
          quality={95}
          aspectRatio={2}
          formats={["auto", "webp", "avif"]}
          alt="eBikes"
          
        />
      </Carousel.Item>
      <Carousel.Item>
        <StaticImage
          src="../images/ebikes/02-ebike.jpg"
          quality={95}
          aspectRatio={2}
          formats={["auto", "webp", "avif"]}
          alt="eBikes"
          
        />
      </Carousel.Item>

      <Carousel.Item>
        <StaticImage
          src="../images/ebikes/03-ebike.jpg"
          quality={95}
          aspectRatio={2}
          formats={["auto", "webp", "avif"]}
          alt="eBikes"
          
        />
      </Carousel.Item>

      <Carousel.Item>
        <StaticImage
          src="../images/ebikes/04-ebike.jpg"
          quality={95}
          aspectRatio={2}
          formats={["auto", "webp", "avif"]}
          alt="eBikes"
          
        />
      </Carousel.Item>

      <Carousel.Item>
        <StaticImage
          src="../images/ebikes/05-ebike.jpg"
          quality={95}
          aspectRatio={2}
          formats={["auto", "webp", "avif"]}
          alt="eBikes"
          
        />
      </Carousel.Item>

      <Carousel.Item>
        <StaticImage
          src="../images/ebikes/06-ebike.jpg"
          quality={95}
          aspectRatio={2}
          formats={["auto", "webp", "avif"]}
          alt="eBikes"
          
        />
      </Carousel.Item>

      <Carousel.Item>
        <StaticImage
          src="../images/ebikes/07-ebike.jpg"
          quality={95}
          aspectRatio={2}
          formats={["auto", "webp", "avif"]}
          alt="eBikes"
          
        />
      </Carousel.Item>
      
    </Carousel>
    <div className="hawaiianBG d-flex justify-content-center py-3">
      <h1>E-bike Hire & Private e-bike Tours</h1>
    </div>
    <Container className="p-4">
    <Row className="g-5">
      <Col lg={8}>
        <p>
          <StaticImage
            src="../images/Bike-for-Sun-Map.jpg"
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="eBike"
          />
        </p>
        <h2>E-bike Hire</h2>
        <p>Explore Rarotonga at your own pace with an e-bike suitable for all fitness levels.</p>
        <p>
          Take a journey on the back roads and chat to friendly locals.  As well as discovering our various plantations, stop and buy some fresh local fruit and veges from a road-side market.  Also pop in and and visit one of our cultural museums and churches.  Seek out the best places to eat and grab an ice cold beverage.  And, don’t forget to dip your toes in our crystal clear waters and take some photos along the way.
        </p>
        <p>
          You can spend the day cruising around Rarotonga (32km) using a combination of standard cycling, and two electric modes – pedal assist and the throttle.  Our e-bikes have fully adjustable handle bars and seats to suit all riding styles and are equipped with front, rear and brake lights for evening riding.  Furthermore, all our bikes come with helmets, chargers, insurance cover and bike locks.
        </p>
        <p>
          There’s no license requirements (minimum age is 17 yrs), and no paying for fuel as you can charge the battery right in your room
        </p>
        <p><strong>Pricing:</strong></p>
        <ul>
          
          <li>1 day - $49</li>
          <li>2 days - $90</li>
          <li>3 days - $120</li>
          <li>4 days - $150</li>
          <li>5 days - $180</li>
          <li>6 days - $210</li>
          <li>7 days - $240</li>
          <li>($30 for each additional day over 7 days)</li>

        </ul>
        
        <hr />

        <h2>Private E-bike Tours</h2>
        <p>
          Let us showcase the beautiful island of Rarotonga and take you on a guided e-bike adventure.  Our private tours are tailor-made to suit your interests and your fitness levels.
        </p>
        <p>
          Included in your private tour is:
        </p>
        <ul>
          <li>Full induction and safety briefing on our e-bikes</li>
          <li>Knowledgeable local guide</li>
          <li>Lunch (optional), water provided</li>
        </ul>
        <p>
          A minimum of two people per booking is required.  Guaranteed maximum of four people per tour.  Our e-bikes have storage for personal items so bring along camera’s, sunscreen and beach towels etc.
        </p>
        <p>
          Tours start from $129 per person.
        </p>

      </Col>
      <Col lg={4}>
        <p className="lead">Recent Press</p>
        <p>
          <a href="http://www.nzherald.co.nz/travel/news/article.cfm?c_id=7&objectid=11731227">
            <StaticImage
              src="../images/nz-herald.png"
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="New Zealand Herald Logo"
            />
          </a>
        </p>
        <p>
          <a href="https://www.usatoday.com/story/travel/destinations/2017/04/01/cook-islands/99882942/">
            <StaticImage
              src="../images/large_USAToday_Logo_Square.jpg"
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="New Zealand Herald Logo"
            />
          </a>
        </p>
        <p>
          <a href="https://kiwiliving.nz/holidays/mike-in-rarotonga">
            <StaticImage
              src="../images/kiwi-living.jpg"
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="New Zealand Herald Logo"
            />
          </a>
        </p>
        
      </Col>
    </Row>
    
    </Container>
      
  </Layout>
)

export default IndexPage
